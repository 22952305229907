<template>
  <div class="confirm-registration">
    <h1>Email Confirmation</h1>
    <h3 style="color:burlywood" v-if="loading">Staðfesti tölvupóst...</h3>
    <h3 style="color: red;" v-else-if="error">Villa kom upp við staðfestingu. Prófaðu aftur.</h3>
    <h3 style="color: green;" v-else>Skráning staðfest. Velkomin/nn í Tólspora kerfið. Byrjaðu á að endursetja lykilorðið.</h3>
    <h2><a class="reset-link" @click="goToResetPassword">Ýttu hér!</a></h2>
  </div>
</template>
  
  <script>
import api from '../../services/api';
  
export default {
  data() {
    return {
      loading: true,
      error: false,
      resetCode: '',
      userId: ''
    };
  },
  async created() {
    try {
      this.userId = this.$route.query.userId;
      const code = this.$route.query.code;
      const encodedCode = encodeURIComponent(code);
      //password reset code
      this.resetCode = encodeURIComponent(this.$route.query.passwordResetCode);
      await api.get(`/identity/ConfirmEmail?userId=${this.userId}&code=${encodedCode}`);
      this.loading = false;
    } catch (error) {
      this.error = true;
      this.loading = false;
    }
  },
  methods: {
    goToResetPassword() {
      this.$router.push({ 
        name: 'Reset Password', 
        params: { 
          userId: this.userId,
          resetCode: this.resetCode 
        } 
      });
    }
  }
};
</script>

<style scoped>
.confirm-registration {
  text-align: center;
  padding: 20px;
}
.reset-link:hover {
  cursor: pointer;
}
</style>
  